<template>
  <fragment>
    <wx-form-container class="wizard">
      <v-form ref="workShiftForm" lazy-validation>
        <v-stepper v-model="wizardStep" alt-labels min-height="445px">
          <!--
            Step_1of3
          -->
          <v-stepper-header>
            <v-divider color="primary" />
            <v-stepper-step step="1" editable>
              {{ $t("shiftWizard.step1Title") }}
            </v-stepper-step>
            <v-divider :color="wizardStep >= 2 ? 'primary' : false" />
            <v-stepper-step step="2">
              {{ $t("shiftWizard.step2Title") }}
            </v-stepper-step>
            <v-divider :color="wizardStep >= 3 ? 'primary' : false" />
            <v-stepper-step step="3">
              {{ $t("shiftWizard.step3Title") }}
              <span class="caption d-block">
                {{ $t("common.optional") }}
              </span>
            </v-stepper-step>
            <v-divider />
          </v-stepper-header>

          <v-stepper-content step="1">
            <v-form ref="workShiftStep1Form" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <!--
                    #1 Name - - - - - - - - - - - - - - - - - -
                  -->
                  <wx-text-field
                    v-model.trim="name"
                    :label="$t('shiftWizard.field.nameLabel')"
                    :rules="[() => this.validateName()]"
                  />
                </v-col>
                <v-col cols="12">
                  <!--
                    #1 Production Units - - - - - - - - - - - -
                  -->
                  <wx-production-unit-multi-selector
                    v-model="selectedProductionUnits"
                    :is-multiple-factories="false"
                    :available-production-units="sortedProductionUnits"
                  />
                </v-col>
                <v-col cols="12">
                  <!-- Out of production transformation -->
                  <v-row class="ml-2">
                    <v-switch
                      class="ml-4 mr-1"
                      v-model="isOopTransformationEnabled"
                      inset
                      :label="$t('shiftWizard.isOopTransformationEnabled')"
                    />
                    <wx-contextualized-help :tooltip-text="$t('shiftWizard.isOopTransformationEnabledHint')" />
                  </v-row>
                </v-col>
                <v-col cols="12" class="step-footer-actions">
                  <!--
                    Action_1to1
                    -->
                  <wx-btn-standard color="secondary" outlined @click="toStep2">
                    {{ $t("common.next") }}
                    <v-icon right>mdi-chevron-right</v-icon>
                  </wx-btn-standard>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <!--
            Step_2of3 - Work Hours
          -->
          <v-stepper-content step="2">
            <v-form ref="workShiftStep2Form" lazy-validation>
              <fieldset class="time-setting-fieldset pb-0">
                <ul class="time-block-list">
                  <li
                    class="time-block-item"
                    v-for="(scheduleBlock, index) in this.workHourTimeBlocks"
                    :key="'scheduleBlock' + index"
                  >
                    <v-row class="header-row">
                      <v-col cols="12">
                        <legend class="legend-header d-flex align-center">
                          <span class="wx-typo-h3">
                            {{ $t("shiftWizard.scheduleBlockIndex", { index: index + 1 }) }}
                          </span>
                          <wx-btn-standard
                            text
                            class="btn-delete ml-auto mt-0"
                            :title="$t('shiftWizard.removeTimeBlockHoverTitle')"
                            :disabled="workHourTimeBlockIsDisabled"
                            @click="removeWorkHourTimeBlock(index)"
                          >
                            <v-icon color="error">mdi-minus-circle</v-icon>
                          </wx-btn-standard>
                        </legend>
                      </v-col>
                    </v-row>
                    <v-row class="time-block-inputs">
                      <!--
                      #2 Start Time - - - - - - - - - - - - - -
                      -->
                      <v-col cols="6" class="pb-0">
                        <wx-time-picker
                          v-model="scheduleBlock.startTime"
                          :title="$t('shiftWizard.field.startAt')"
                          :rules="[
                            () => validateTime(index + 1, scheduleBlock.startTime),
                            () =>
                              validateTimeBlockStartEndTime(index + 1, scheduleBlock.startTime, scheduleBlock.endTime),
                          ]"
                          :show-prepend-icon="false"
                          :error-messages="[]"
                          @updated="revalidate()"
                        />
                      </v-col>

                      <!--
                        #2of3 End Time - - - - - - - - - - - - - - -
                      -->
                      <v-col cols="6" class="pb-sm-0">
                        <wx-time-picker
                          v-model="scheduleBlock.endTime"
                          :title="$t('shiftWizard.field.endAt')"
                          :rules="[
                            () => validateTime(index + 1, scheduleBlock.endTime),
                            () =>
                              validateTimeBlockStartEndTime(index + 1, scheduleBlock.startTime, scheduleBlock.endTime),
                          ]"
                          :show-prepend-icon="false"
                          :error-messages="[]"
                          @updated="revalidate()"
                        />
                      </v-col>

                      <!--
                      #2of3 Selected Days - - - - - - - - - - - - -
                      -->
                      <v-col cols="12" class="pt-sm-0">
                        <wx-checkbox-weekdays
                          v-model="scheduleBlock.weekdays"
                          show-days-first-character
                          :checkboxes-main-label="$t('shiftWizard.field.daysLabel')"
                          :rules="[() => validateDays(index + 1, scheduleBlock.weekdays)]"
                          @updated="revalidate()"
                        />
                      </v-col>
                    </v-row>
                  </li>
                </ul>
              </fieldset>
              <!--
                #2of3 addNewWorkHourTimeBlock
                -->
              <footer class="d-flex flex-row-reverse">
                <wx-btn-standard
                  text
                  :title="$t('shiftWizard.addTimeBlockHoverTitle')"
                  @click="addNewWorkHourTimeBlock"
                >
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t("shiftWizard.addTimeBlock") }}
                </wx-btn-standard>
              </footer>

              <v-row>
                <v-col cols="12" class="step-footer-actions">
                  <!--
                    Action_2to3
                    -->
                  <wx-btn-standard color="secondary" outlined @click="wizardStep = 1">
                    <v-icon left>mdi-chevron-left</v-icon>
                    {{ $t("common.back") }}
                  </wx-btn-standard>
                  <wx-btn-standard color="secondary" outlined @click="toStep3">
                    {{ $t("common.next") }}
                    <v-icon right>mdi-chevron-right</v-icon>
                  </wx-btn-standard>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <!--
            Step_3of3 - Recurring Downtimes
          -->
          <v-stepper-content step="3">
            <v-form ref="workShiftStep3Form" lazy-validation>
              <fieldset class="time-setting-fieldset pb-0">
                <ul class="time-block-list">
                  <li
                    class="time-block-item"
                    v-for="(downtimeBlock, index) in this.recurringDowntimeTimeBlocks"
                    :key="'downtimeBlock' + index"
                  >
                    <v-row class="header-row">
                      <v-col cols="12">
                        <legend class="legend-header d-flex align-center">
                          <span class="wx-typo-h3">
                            {{ $t("shiftWizard.downtimeBlockIndex", { index: index + 1 }) }}
                          </span>
                          <wx-btn-standard
                            text
                            class="btn-delete ml-auto mt-0"
                            :title="$t('shiftWizard.removeTimeBlockHoverTitle')"
                            @click="removeRecurringDowntimeTimeBlock(index)"
                          >
                            <v-icon color="error">mdi-minus-circle</v-icon>
                          </wx-btn-standard>
                        </legend>
                      </v-col>
                    </v-row>
                    <v-row class="time-block-inputs">
                      <!--
                      #1of4 Selected Downtime Reason - - - - - - - - - - - - -
                      -->
                      <v-col cols="12" class="pt-sm-0 mt-4">
                        <wx-autocomplete
                          v-model="downtimeBlock.reason"
                          :items="availableReasons"
                          :rules="[() => validateDowntimeReason(index + 1, downtimeBlock.reason)]"
                          :label="$t('justification.panel.selectADowntimeReason')"
                          item-text="label"
                          return-object
                          validate-on-blur
                          class="input-reason"
                        />
                      </v-col>

                      <!--
                      #2of4 Start Time - - - - - - - - - - - - - -
                      -->
                      <v-col cols="6" class="pb-0">
                        <wx-time-picker
                          v-model="downtimeBlock.startTime"
                          :title="$t('shiftWizard.field.startAt')"
                          :rules="[() => validateDowntimeStartTime(index + 1, downtimeBlock.startTime)]"
                          :show-prepend-icon="false"
                          :error-messages="[]"
                          @updated="revalidate()"
                        />
                      </v-col>

                      <!--
                        #3of4 End Time - - - - - - - - - - - - - - -
                      -->
                      <v-col cols="6" class="pb-sm-0">
                        <wx-time-picker
                          v-model="downtimeBlock.endTime"
                          :title="$t('shiftWizard.field.endAt')"
                          :rules="[() => validateDowntimeEndTime(index + 1, downtimeBlock.endTime)]"
                          :show-prepend-icon="false"
                          :error-messages="[]"
                          @updated="revalidate()"
                        />
                      </v-col>

                      <!--
                      #4of4 Selected Days - - - - - - - - - - - - -
                      -->
                      <v-col cols="12" class="pt-sm-0 mt-4">
                        <wx-checkbox-weekdays
                          v-model="downtimeBlock.weekdays"
                          show-days-first-character
                          :checkboxes-main-label="$t('shiftWizard.field.daysLabel')"
                          :rules="[() => validateDowntimeDays(index + 1, downtimeBlock.weekdays)]"
                          @updated="revalidate()"
                        />
                      </v-col>
                    </v-row>
                  </li>
                </ul>
              </fieldset>
              <!--
                #3of3 addNewRecurringDowntimeTimeBlock
                -->
              <footer class="d-flex flex-row-reverse">
                <wx-btn-standard
                  text
                  :title="$t('shiftWizard.addRecurringDowntimeHoverTitle')"
                  @click="addNewRecurringDowntimeTimeBlock"
                >
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t("shiftWizard.addRecurringDowntimeBlock") }}
                </wx-btn-standard>
              </footer>

              <v-row>
                <v-col cols="12" class="step-footer-actions">
                  <!--
                    Action_3to2
                    -->
                  <wx-btn-standard color="secondary" outlined @click="wizardStep = 2">
                    <v-icon left>mdi-chevron-left</v-icon>
                    {{ $t("common.back") }}
                  </wx-btn-standard>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper>

        <v-divider />

        <!--
          WxFormFooter = = = = = = = = = = = = = = = =
        -->
        <form-footer-actions
          v-if="isWorkShiftEdition()"
          :click-submit="submitWorkShift"
          cancel-to="toShiftSchedules"
          :click-delete="deleteWorkShift"
          :deletion-title="$t('shiftWizard.deletion.title')"
          :deletion-info-text="getDeletionInfoText()"
          :deletion-confirmation-prefix="getDeletionConfirmationPrefix()"
          :item-to-delete-display-name="getToDeleteDisplayName()"
        />
        <form-footer-actions
          v-if="isWorkShiftCreation()"
          :click-submit="submitWorkShift"
          :disabled-click-submit="isSubmitButtonForCreationDisabled"
          cancel-to="toShiftSchedules"
        />
      </v-form>
    </wx-form-container>
  </fragment>
</template>

<script>
import RouteService from "@/router/RouteService";
import WorkShiftService from "@/components/shiftscheduler/WorkShiftService";
import ErrorHandling from "@/components/ErrorHandling";
import Validations from "@/components/validations";
import { DateTime } from "luxon";
import { mapActions, mapGetters } from "vuex";
import WxFormContainer from "@/components/ui/WxFormContainer";
import FormFooterActions from "@/components/ui/FormFooterActions";
import WxTextField from "@/components/ui/WxTextField";
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxCheckboxWeekdays from "@/components/ui/WxCheckboxWeekdays";
import WxTimePicker from "@/components/ui/WxTimePicker";
import DowntimeReasonService from "@/components/downtimereason/DowntimeReasonService";
import WxProductionUnitMultiSelector from "@/components/ui/WxProductionUnitMultiSelector";
import WxContextualizedHelp from "@/components/ui/WxContextualizedHelp";
import { compareName } from "@/components/SortUtils";

export default {
  name: "WorkShiftWizard",
  components: {
    WxFormContainer,
    FormFooterActions,
    WxTextField,
    WxAutocomplete,
    WxBtnStandard,
    WxCheckboxWeekdays,
    WxTimePicker,
    WxProductionUnitMultiSelector,
    WxContextualizedHelp,
  },
  props: {
    initialWorkShift: {
      type: Object,
      default: null,
    },
    factoryId: {
      type: String,
      required: true,
    },
    productionUnits: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      name: null,
      originalName: null,
      nameMaxLength: 100,
      isOopTransformationEnabled: true,
      selectedProductionUnits: [],
      workHourTimeBlocks: [
        {
          displayStartTimePicker: false,
          displayEndTimePicker: false,
          startTime: "",
          endTime: "",
          weekdays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
          },
        },
      ],
      recurringDowntimeTimeBlocks: [
        {
          displayStartTimePicker: false,
          displayEndTimePicker: false,
          startTime: "",
          endTime: "",
          weekdays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
          },
          reason: null,
        },
      ],
      availableReasons: [],
      wizardStep: 1,
    };
  },
  watch: {
    async initialWorkShift() {
      if (this.initialWorkShift && this.productionUnits) {
        this.id = this.initialWorkShift.id;
        this.name = this.initialWorkShift.name;
        this.originalName = this.initialWorkShift.name;
        this.isOopTransformationEnabled = this.initialWorkShift.is_oop_transformation_enabled ?? true;

        let puList = [];
        if (this.initialWorkShift.associated_production_unit_ids) {
          this.initialWorkShift.associated_production_unit_ids.forEach((puId) => {
            const pu = this.activeFactoryProductionUnits.find((pu) => pu.id === puId);
            if (pu) {
              puList.push(pu);
            }
          });
        }
        this.selectedProductionUnits = puList;

        // Time block are `work_hours` that have common start and end time
        let workHoursByPeriod = new Map();
        this.initialWorkShift.work_hours.forEach((wh) => {
          const periodAsString = `${wh.start_time}-${wh.end_time}`; // Example: 07:00-15:00
          const foundWorkHours = workHoursByPeriod.get(periodAsString);
          if (foundWorkHours) {
            foundWorkHours.push(wh);
          } else {
            let list = [];
            list.push(wh);
            workHoursByPeriod.set(periodAsString, list);
          }
        });
        let recurringDowntimesByPeriod = new Map();
        this.initialWorkShift.recurring_downtimes.forEach((wh) => {
          const periodAsString = `${wh.start_time}-${wh.end_time}-${wh.downtime_reason_id}`; // Example: 07:00-15:00-11111111-1111-1111-1111-11111111
          const foundWorkHours = recurringDowntimesByPeriod.get(periodAsString);
          if (foundWorkHours) {
            foundWorkHours.push(wh);
          } else {
            let list = [];
            list.push(wh);
            recurringDowntimesByPeriod.set(periodAsString, list);
          }
        });
        // Here, `workHoursByPeriod` is a Map where the key is the Start & End time of a block.
        // The map's value is a list of `work_hours`
        // Each key-value IS is a time block. Let;s convert them into `timeBlock`s
        let initialTimeBlocks = [];
        workHoursByPeriod.forEach((workHoursList, blockStartAndEndTime) => {
          const weekdays = this.convertWorkHoursToTimeBlocks(workHoursList);
          initialTimeBlocks.push({
            displayStartTimePicker: false,
            displayEndTimePicker: false,
            startTime: blockStartAndEndTime.slice(0, 5),
            endTime: blockStartAndEndTime.slice(6),
            weekdays: weekdays,
          });
        });
        this.workHourTimeBlocks = initialTimeBlocks;

        // Here, `recurringDowntimesByPeriod` is a Map where the key is the Start & End time of a block.
        // The map's value is a list of `recurring_downtimes`
        // Each key-value IS is a time block. Let;s convert them into `timeBlock`s
        initialTimeBlocks = [];
        if (recurringDowntimesByPeriod.size > 0) {
          let reasonById = new Map();
          await DowntimeReasonService.getDowntimeReasonTree(this.activeFactory.id)
            .then((httpResponse) => {
              let plannedReasons = [];
              DowntimeReasonService.extractReasonsFromTree(httpResponse.data.children, plannedReasons);
              plannedReasons.forEach((r) => reasonById.set(r.id, r));
            })
            .catch((error) => this.handleDowntimeReasonTreeRetrievalError(error.response));

          recurringDowntimesByPeriod.forEach((recurringDowntimesList, blockStartAndEndTime) => {
            const weekdays = this.convertWorkHoursToTimeBlocks(recurringDowntimesList);
            const downtimeReason = reasonById.get(recurringDowntimesList[0].downtime_reason_id);
            initialTimeBlocks.push({
              displayStartTimePicker: false,
              displayEndTimePicker: false,
              startTime: blockStartAndEndTime.slice(0, 5),
              endTime: blockStartAndEndTime.slice(6, 11),
              weekdays: weekdays,
              reason: {
                id: downtimeReason.id,
                code: downtimeReason.code,
                name: downtimeReason.name,
                label: `${downtimeReason.code} - ${downtimeReason.name}`,
              },
            });
          });
        }
        this.recurringDowntimeTimeBlocks = initialTimeBlocks;
      }
    },
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits"]),
    sortedProductionUnits() {
      if (this.activeFactoryProductionUnits && this.activeFactoryProductionUnits.length > 0) {
        let puList = [...this.activeFactoryProductionUnits];
        puList.sort(compareName);
        return puList;
      }
      return [];
    },
    workHourTimeBlockIsDisabled() {
      return this.workHourTimeBlocks.length === 1;
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationSuccess", "showOperationError", "setDisplayTimeout"]),
    getRouteBack() {
      return RouteService.toShiftSchedules();
    },
    isSubmitButtonForCreationDisabled() {
      return this.wizardStep < 2;
    },
    isWorkShiftCreation() {
      return this.id === null;
    },
    isWorkShiftEdition() {
      return this.id !== null;
    },
    addNewWorkHourTimeBlock() {
      this.workHourTimeBlocks.push({
        displayStartTimePicker: false,
        displayEndTimePicker: false,
        startTime: "",
        endTime: "",
        weekdays: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        },
      });
    },
    addNewRecurringDowntimeTimeBlock() {
      this.recurringDowntimeTimeBlocks.push({
        displayStartTimePicker: false,
        displayEndTimePicker: false,
        reason: null,
        startTime: "",
        endTime: "",
        weekdays: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        },
      });
    },
    removeWorkHourTimeBlock(index) {
      this.workHourTimeBlocks.splice(index, 1);
    },
    removeRecurringDowntimeTimeBlock(index) {
      this.recurringDowntimeTimeBlocks.splice(index, 1);
    },
    validateName() {
      if (!this.name) {
        return this.$t("shiftWizard.errors.noName");
      }
      if (this.name.trim().length > this.nameMaxLength) {
        return this.$t("shiftWizard.errors.nameTooLong", {
          maxLength: this.nameMaxLength,
        });
      }
      if (!Validations.isWorkShiftNameValid(this.name)) {
        return this.$t("shiftWizard.errors.nameHasInvalidChar");
      }
      return true;
    },
    validateTime(blockIndex, value) {
      if (!value) {
        return this.$t("shiftWizard.errors.noTime", { index: blockIndex });
      }
      return true;
    },
    validateTimeBlockStartEndTime(blockIndex, start, end) {
      if (start && end) {
        const startTime = DateTime.fromFormat(start, "HH:mm");
        const endTime = DateTime.fromFormat(end, "HH:mm");
        if (startTime.isValid && endTime.isValid) {
          if (startTime.hour === endTime.hour && startTime.minute === endTime.minute) {
            // This is 24-hours work shift
            return true;
          }
          const diffInMinutes = endTime.diff(startTime, "minute").as("minute");
          if (diffInMinutes > 0) {
            // The work shift does not cross midnight
            if (diffInMinutes < 30) {
              return this.$t("shiftWizard.errors.invalidMinWorkHourDuration");
            }
          } else {
            // The work shift crosses midnight
            const endOfDay = DateTime.fromFormat("23:59:59.999", "HH:mm:ss.SSS");
            const minutesBetweenStartTimeAndMidnight = Math.round(endOfDay.diff(startTime, "minute").as("minute"));
            const startOfDay = DateTime.fromFormat("00:00", "HH:mm");
            const minutesBetweenMidnightAndEndTime = endTime.diff(startOfDay, "minute").as("minute");
            const diffInMinutes = minutesBetweenStartTimeAndMidnight + minutesBetweenMidnightAndEndTime;
            if (diffInMinutes < 30) {
              return this.$t("shiftWizard.errors.invalidMinWorkHourDuration");
            }
          }
        }
      }
      return true;
    },
    validateDays(blockIndex, weekDays) {
      if (!weekDays) {
        return this.$t("shiftWizard.errors.noDays", { index: blockIndex });
      }
      if (
        weekDays.monday === false &&
        weekDays.tuesday === false &&
        weekDays.wednesday === false &&
        weekDays.thursday === false &&
        weekDays.friday === false &&
        weekDays.saturday === false &&
        weekDays.sunday === false
      ) {
        return this.$t("shiftWizard.errors.noDays", { index: blockIndex });
      }
      return true;
    },
    noRecurringDowntimeDefined() {
      return this.recurringDowntimeTimeBlocks.every((d) => {
        return (
          !d.reason &&
          (!d.startTime || d.startTime === "") &&
          (!d.endTime || d.endTime === "") &&
          d.weekdays.monday === false &&
          d.weekdays.tuesday === false &&
          d.weekdays.wednesday === false &&
          d.weekdays.thursday === false &&
          d.weekdays.friday === false &&
          d.weekdays.saturday === false &&
          d.weekdays.sunday === false
        );
      });
    },
    validateDowntimeReason(blockIndex, selectedReason) {
      if (this.noRecurringDowntimeDefined()) return true; // no recurring downtime defined, no error.

      if (!selectedReason) {
        return this.$t("shiftWizard.errors.recurringDowntime.noReason", { index: blockIndex });
      }
      return true;
    },
    validateDowntimeStartTime(blockIndex, startTime) {
      if (this.noRecurringDowntimeDefined()) return true; // no recurring downtime defined, no error.

      return this.validateTime(blockIndex, startTime);
    },
    validateDowntimeEndTime(blockIndex, endTime) {
      if (this.noRecurringDowntimeDefined()) return true; // no recurring downtime defined, no error.

      return this.validateTime(blockIndex, endTime);
    },
    validateDowntimeDays(blockIndex, days) {
      if (this.noRecurringDowntimeDefined()) return true; // no recurring downtime defined, no error.

      return this.validateDays(blockIndex, days);
    },
    validateAll() {
      return (
        this.$refs.workShiftStep1Form.validate() &&
        this.$refs.workShiftStep2Form.validate() &&
        this.$refs.workShiftStep3Form.validate()
      );
    },
    revalidate() {
      this.validateAll();
    },
    toStep2() {
      let isValid = this.$refs.workShiftStep1Form.validate();
      if (isValid) {
        this.$refs.workShiftStep2Form.resetValidation();
        this.wizardStep = 2;

        return DowntimeReasonService.getDowntimeReasonTree(this.activeFactory.id)
          .then((response) => this.handleDowntimeReasonTreeRetrievalResponse(response))
          .catch((error) => this.handleDowntimeReasonTreeRetrievalError(error.response));
      }
    },
    handleDowntimeReasonTreeRetrievalResponse(httpResponse) {
      function isAssociatedToAllPU(reason, userSelectedProductionUnitIds) {
        return userSelectedProductionUnitIds.every((puId) => reason.production_unit_ids.includes(puId));
      }
      let plannedReasons = [];
      DowntimeReasonService.extractReasonsFromTree(httpResponse.data.children, plannedReasons);
      const selectedProductionUnitIds = this.selectedProductionUnits.map((puSelection) => puSelection.id);
      this.availableReasons = plannedReasons
        .filter((r) => isAssociatedToAllPU(r, selectedProductionUnitIds))
        .map((r) => ({
          id: r.id,
          code: r.code,
          name: r.name,
          label: `${r.code} - ${r.name}`,
        }));
    },
    async toStep3() {
      let isValid = this.$refs.workShiftStep2Form.validate();
      if (isValid) {
        // First, create the WorkShift
        const workShiftAfterStep2 = this.buildWorkShiftRequest();
        workShiftAfterStep2.recurring_downtimes = [];
        await WorkShiftService.validateWorkShiftUpdate(this.factoryId, workShiftAfterStep2)
          .then(() => {
            this.$refs.workShiftStep3Form.resetValidation();
            this.wizardStep = 3;
          })
          .catch((error) => this.handleWorkShiftError(error.response));
      }
    },
    submitWorkShift() {
      if (this.isWorkShiftCreation()) {
        this.create();
      }
      if (this.isWorkShiftEdition()) {
        this.update();
      }
    },
    create() {
      const isBasicValidationSuccess = this.validateAll();
      if (isBasicValidationSuccess) {
        const workShift = this.buildWorkShiftRequest();
        WorkShiftService.validateWorkShiftCreation(this.factoryId, workShift)
          .then(() => this.handleCreationValidationResponse(workShift))
          .catch((error) => this.handleWorkShiftError(error.response));
      }
    },
    handleCreationValidationResponse(workShift) {
      // No validation errors reported
      WorkShiftService.createWorkShift(this.factoryId, workShift)
        .then((httpResponse) => this.handleWorkShiftCreationResponse(httpResponse))
        .catch((error) => this.handleWorkShiftError(error.response));
    },
    handleWorkShiftCreationResponse(httpResponse) {
      if (httpResponse.status === 201) {
        const workShiftName = httpResponse.data.name;
        this.showOperationSuccess(this.$t("shiftWizard.successfullyCreated", { name: workShiftName }));
        this.close();
      }
    },
    update() {
      const isBasicValidationSuccess = this.validateAll();
      if (isBasicValidationSuccess) {
        // First, create the WorkShift
        const workShift = this.buildWorkShiftRequest();
        WorkShiftService.validateWorkShiftUpdate(this.factoryId, workShift)
          .then(() => this.handleUpdateValidationResponse(workShift))
          .catch((error) => this.handleWorkShiftError(error.response));
      }
    },
    handleUpdateValidationResponse(workShift) {
      // No validation errors reported
      WorkShiftService.updateWorkShift(this.id, workShift)
        .then((httpResponse) => this.handleWorkShiftUpdateResponse(httpResponse))
        .catch((error) => this.handleWorkShiftError(error.response));
    },
    handleWorkShiftUpdateResponse(httpResponse) {
      if (httpResponse.status === 200) {
        const workShiftName = httpResponse.data.name;
        this.showOperationSuccess(this.$t("shiftWizard.successfullyUpdated", { name: workShiftName }));
        this.close();
      }
    },
    handleWorkShiftError(httpResponse) {
      const errorMessages = ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage, false);
      if (errorMessages.length > 1) {
        this.setDisplayTimeout(30000);
      }
      this.showOperationError(errorMessages);
    },
    getErrorMessage(code, args) {
      switch (code) {
        case "CS_WS_PUT_10003":
        case "CS_WS_POST_10003": {
          return this.$t("shiftWizard.errors.nameHasInvalidChar");
        }
        case "CS_WS_PUT_10004":
        case "CS_WS_POST_10004": {
          return this.$t("shiftWizard.errors.noWorkHours");
        }
        case "CS_WS_PUT_10013":
        case "CS_WS_POST_10013": {
          return this.$t("shiftWizard.errors.workHoursOverlapping");
        }
        case "CS_WS_PUT_10014":
        case "CS_WS_POST_10014": {
          return this.$t("shiftWizard.errors.invalidMaxWorkHourDuration");
        }
        case "CS_WS_PUT_10016":
        case "CS_WS_POST_10016": {
          return this.$t("shiftWizard.errors.invalidMinWorkHourDuration");
        }
        case "CS_WS_PUT_10026":
        case "CS_WS_POST_10026": {
          return this.$t("shiftWizard.errors.recurringDowntime.overlaps");
        }
        case "CS_WS_PUT_10027":
        case "CS_WS_POST_10027": {
          return this.$t("shiftWizard.errors.recurringDowntime.outsideWorkHours", {
            startDay: args.start_day,
            startTime: this.trimSeconds(args.start_time),
            endDay: args.end_day,
            endTime: this.trimSeconds(args.end_time),
          });
        }
        case "CS_WS_PUT_10028":
        case "CS_WS_POST_10028": {
          return this.$t("shiftWizard.errors.recurringDowntime.tooMany", {
            maxRecurringDowntimes: args.max_recurring_downtimes,
          });
        }
        case "CS_WS_PUT_10029":
        case "CS_WS_POST_10029": {
          return this.$t("shiftWizard.errors.recurringDowntime.startAfterOrEqualToEnd", {
            startDay: args.start_day,
            startTime: this.trimSeconds(args.start_time),
            endDay: args.end_day,
            endTime: this.trimSeconds(args.end_time),
          });
        }
        case "CS_WS_PUT_20001":
        case "CS_WS_POST_20001": {
          return this.$t("shiftWizard.errors.duplicateWorkShiftName", {
            name: this.name,
          });
        }
        case "CS_WS_PUT_20002":
        case "CS_WS_POST_20002": {
          return this.$t("shiftWizard.errors.overlapWithExistingShifts", {
            puName: args.production_unit_name,
          });
        }
        case "CS_WS_PUT_20006":
        case "CS_WS_POST_20006": {
          return this.$t("shiftWizard.errors.recurringDowntime.reasonMissingAssociations");
        }
      }
      return this.$t("common.errors.default", { code: code });
    },
    buildWorkShiftRequest() {
      let workHours = [];
      let recurringDowntimes = [];
      this.workHourTimeBlocks.forEach((block) => {
        const startTime = this.trimSeconds(block.startTime);
        const endTime = this.trimSeconds(block.endTime);
        const workHoursFromBlock = this.getSelectedDays(block.weekdays).map((day) => ({
          start_day: day,
          start_time: startTime,
          end_day: this.getEndDay(day, startTime, endTime),
          end_time: endTime,
        }));
        workHours.push(...workHoursFromBlock);
      });
      this.recurringDowntimeTimeBlocks.forEach((block) => {
        const startTime = this.trimSeconds(block.startTime);
        const endTime = this.trimSeconds(block.endTime);
        const recurringDowntimesFromBlock = this.getSelectedDays(block.weekdays).map((day) => ({
          start_day: day,
          start_time: startTime,
          end_day: this.getEndDay(day, startTime, endTime),
          end_time: endTime,
          downtime_reason_id: block.reason.id,
        }));
        recurringDowntimes.push(...recurringDowntimesFromBlock);
      });
      const productionUnitIds = this.selectedProductionUnits.map((puSelection) => puSelection.id);
      return {
        name: this.name,
        work_hours: workHours,
        is_oop_transformation_enabled: this.isOopTransformationEnabled,
        recurring_downtimes: recurringDowntimes,
        production_unit_ids: productionUnitIds,
      };
    },
    trimSeconds(time) {
      if (time && time.length > 5) {
        // Format is HH:mm:ss, trim seconds
        return time.slice(0, 5);
      }
      return time;
    },
    getSelectedDays(blockWeekdays) {
      let days = [];
      if (blockWeekdays.monday) days.push("monday");
      if (blockWeekdays.tuesday) days.push("tuesday");
      if (blockWeekdays.wednesday) days.push("wednesday");
      if (blockWeekdays.thursday) days.push("thursday");
      if (blockWeekdays.friday) days.push("friday");
      if (blockWeekdays.saturday) days.push("saturday");
      if (blockWeekdays.sunday) days.push("sunday");
      return days;
    },
    getEndDay(day, blockStartTime, blockEndTime) {
      const startISO = "2020-01-10 " + blockStartTime + ":00+00:00";
      const endISO = "2020-01-10 " + blockEndTime + ":00+00:00";
      const start = DateTime.fromFormat(startISO, "yyyy-LL-dd HH:mm:ssZZ");
      const end = DateTime.fromFormat(endISO, "yyyy-LL-dd HH:mm:ssZZ");
      if (start < end) {
        // `blockEndTime` is on the same day as `blockStartTime`
        return day;
      } else {
        // `blockEndTime` is set before `blockStartTime`. It means is end the day after start
        if (day === "monday") return "tuesday";
        if (day === "tuesday") return "wednesday";
        if (day === "wednesday") return "thursday";
        if (day === "thursday") return "friday";
        if (day === "friday") return "saturday";
        if (day === "saturday") return "sunday";
        if (day === "sunday") return "monday";
      }
    },
    convertWorkHoursToTimeBlocks(workHoursList) {
      let weekdays = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      };
      workHoursList.forEach((wh) => {
        switch (wh.start_day) {
          case "monday": {
            weekdays.monday = true;
            break;
          }
          case "tuesday": {
            weekdays.tuesday = true;
            break;
          }
          case "wednesday": {
            weekdays.wednesday = true;
            break;
          }
          case "thursday": {
            weekdays.thursday = true;
            break;
          }
          case "friday": {
            weekdays.friday = true;
            break;
          }
          case "saturday": {
            weekdays.saturday = true;
            break;
          }
          case "sunday": {
            weekdays.sunday = true;
            break;
          }
        }
      });
      return weekdays;
    },
    deleteWorkShift() {
      if (this.id) {
        WorkShiftService.deleteWorkShift(this.id)
          .then((response) => this.handleWorkShiftDeletionResponse(response))
          .catch((error) => this.handleWorkShiftError(error.response));
      }
    },
    handleWorkShiftDeletionResponse(httpResponse) {
      if (httpResponse.status === 204) {
        this.showOperationSuccess(this.$t("shiftWizard.successfullyDeleted", { name: this.originalName }));
      }
      this.$router.push(RouteService.toShiftSchedules());
    },
    getDeletionInfoText() {
      return this.$t("shiftWizard.deletion.warningMessage");
    },
    getDeletionConfirmationPrefix() {
      return this.$t("shiftWizard.deletion.confirmMessagePrefix");
    },
    getToDeleteDisplayName() {
      return this.isWorkShiftEdition() ? this.initialWorkShift.name : "";
    },
    close() {
      this.id = null;
      this.name = null;
      this.nameMaxLength = 100;
      this.selectedProductionUnits = [];
      this.workHourTimeBlocks = [];
      this.recurringDowntimeTimeBlocks = [];
      this.$refs.workShiftForm.resetValidation();
      this.$router.push(this.getRouteBack());
    },
    getSelectedWeekDays(weekdays) {
      let tempDays = [];
      Object.keys(weekdays).forEach((day) => {
        // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
        if (weekdays[day]) tempDays.push(this.$t(`common.dayNames.${day}`));
      });
      return tempDays;
    },
  },
};
</script>

<style lang="scss" scoped>
// local var
$field_vertical_gutter: 40px;

// Custom steppers contents
.v-stepper {
  // Custom controls container
  .step-footer-actions {
    display: flex;
    margin-top: $field_vertical_gutter;

    &.col {
      padding-top: 0;
      padding-bottom: 0;
    }

    .v-btn {
      margin-left: 0;
      margin-right: var(--box-padding-admin);
    }
  }
}

.time-setting-fieldset {
  .time-block-item {
    margin-top: 0;
    margin-bottom: $field_vertical_gutter;

    ::v-deep .legend-header {
      margin-top: 0;
    }
  }
}

::v-deep .form-footer-actions {
  margin-top: var(--field-vertical-gutter);
}

.form-summary {
  &__heading {
    font-weight: 400;
    font-size: var(--font-size-sm);
    color: var(--color-text-subtle-theme);
    opacity: 0.7;
  }
  &__content {
    .time {
      white-space: nowrap;

      // fix wx-list-inline-items.text-right
      @media ($wx-sm-min) {
        &::after {
          padding-right: 0;
        }
      }
    }
  }
}
</style>
