import http from "../../BaseServiceHttp";

class WorkShiftService {
  getWorkShifts(factoryId) {
    return http.get(`factories/${factoryId}/work-shifts`);
  }
  getWorkShift(workShiftId) {
    return http.get(`work-shifts/${workShiftId}`);
  }
  validateWorkShiftCreation(factoryId, workShift) {
    return http.post(`factories/${factoryId}/work-shifts/creation-validation`, workShift);
  }
  createWorkShift(factoryId, workShift) {
    return http.post(`factories/${factoryId}/work-shifts`, workShift);
  }
  validateWorkShiftUpdate(workShiftId, workShift) {
    return http.post(`work-shifts/${workShiftId}/update-validation`, workShift);
  }
  updateWorkShift(workShiftId, workShift) {
    return http.put(`work-shifts/${workShiftId}`, workShift);
  }
  deleteWorkShift(workShiftId) {
    return http.delete(`work-shifts/${workShiftId}`);
  }
}

export default new WorkShiftService();
