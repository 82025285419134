import http from "../../BaseServiceHttp";

class DowntimeReasonService {
  getDowntimeReasonById(downtimeReasonId) {
    return http.get(`downtime-reasons/${downtimeReasonId}`);
  }
  getDowntimeReasonTree(factoryId) {
    return http.get(`/factories/${factoryId}/downtime-reasons-tree`);
  }
  createCategory(factoryId, newCategory) {
    return http.post(`/factories/${factoryId}/downtime-reason-categories`, newCategory);
  }
  updateCategory(categoryId, updatedCategory) {
    return http.put(`/downtime-reason-categories/${categoryId}`, updatedCategory);
  }
  createReason(factoryId, newReason) {
    return http.post(`/factories/${factoryId}/downtime-reasons`, newReason);
  }
  updateReason(reasonId, updatedReason) {
    return http.put(`/downtime-reasons/${reasonId}`, updatedReason);
  }
  deleteCategory(downtimeCategoryId) {
    return http.delete(`/downtime-reason-categories/${downtimeCategoryId}`);
  }
  deleteReason(downtimeReasonId) {
    return http.delete(`/downtime-reasons/${downtimeReasonId}`);
  }
  // Utility functions
  extractReasonsFromTree(downtimeReasonTree, destinationList) {
    downtimeReasonTree.forEach((element) => {
      if (element.type === "category") {
        if (element.children.length > 0) {
          this.extractReasonsFromTree(element.children, destinationList);
        }
      } else {
        if (element.planned) {
          destinationList.push(element);
        }
      }
    });
  }
}

export default new DowntimeReasonService();
