<template>
  <fragment>
    <!-- - - - - - - - - - - - - - - - - - - - - - - -
      Single Paragraph Help Tooltip
      -->
    <v-tooltip
      v-if="!isHelpCard || tooltipText"
      :wxid="$options.name"
      :max-width="maxWidth"
      content-class="wx-contextualized-help--tooltip"
      tooltip-padding="20px 10px"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" class="wx-contextualized-help__activator icon" small>
          mdi-help-circle-outline
        </v-icon>
      </template>
      <p class="mb-0">{{ tooltipText }}</p>
    </v-tooltip>
    <!-- - - - - - - - - - - - - - - - - - - - - - - -
      Help Card
      contains rich HTML contents
      designers refers to these as `Rich Tolltips`
      -->
    <v-menu
      v-if="isHelpCard"
      :wxid="$options.name"
      :max-width="maxWidth"
      :close-on-content-click="false"
      class="wx-contextualized-help--menu"
      open-on-hover
      offset-x
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-if="helpCardActivatorText" v-bind="attrs" v-on="on" class="wx-contextualized-help__activator text">
          {{ helpCardActivatorText }}
        </span>
        <v-icon v-else v-bind="attrs" v-on="on" class="wx-contextualized-help__activator icon" small>
          mdi-help-circle-outline
        </v-icon>
      </template>
      <v-card
        :wxid="$options.name"
        tag="article"
        :class="{ 'color-inverted-theme': colorInvertedThemeAsTooltips }"
        class="wx-contextualized-help--card pa-3 wx-typo-sm"
        rounded="lg"
      >
        <v-card-title v-if="helpCardTitle" tag="header" class="px-1 py-0 unbreak-word">
          {{ helpCardTitle }}
        </v-card-title>
        <v-card-text tag="section" class="px-1 pb-1">
          <slot name="help-card-text-slot" />
        </v-card-text>
      </v-card>
    </v-menu>
  </fragment>
</template>

<script>
export default {
  name: "WxContextualizedHelp",
  props: {
    tooltipText: {
      type: String,
      default: null,
    },
    maxWidth: {
      type: Number,
      default: 500,
    },
    isHelpCard: {
      type: Boolean,
      default: false,
    },
    helpCardTitle: {
      type: String,
      default: null,
    },
    helpCardActivatorText: {
      type: String,
      default: null,
    },
    colorInvertedThemeAsTooltips: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.wx-contextualized-help {
  &__activator {
    &.icon {
      opacity: 0.6;
    }
    &.text {
      cursor: help; // same interrogation symbol as mdi-help icon
    }
  }
  // Help Card
  &--card {
    &.v-card {
      padding: var(--dialog-padding);
      color: var(--color-text-theme);
      background-color: var(--color-element-layer3);
      opacity: 1;

      // BEM
      .v-card {
        &__title {
          font-size: var(--font-size-sm);
          font-weight: 700; /* Bold */
        }
        &__text {
          color: var(--color-text-theme);
        }
      }

      // if prop colorInvertedThemeAsTooltips = true
      &.color-inverted-theme {
        background-color: var(--color-inverted-container-theme);
        &,
        .v-card__text {
          color: var(--color-inverted-text-theme);
        }
      }
    }
  }
}
</style>
