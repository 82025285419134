<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ title }}</h1>

    <div v-show="showWizard">
      <work-shift-wizard
        v-if="isFactoryIdDefined"
        :factory-id="factoryId"
        :initial-work-shift="initialShift"
        :production-units="productionUnits"
      />
    </div>
  </article>
</template>

<script>
import WorkShiftWizard from "@/components/shiftscheduler/WorkShiftWizard";
import { mapActions, mapGetters } from "vuex";
import WorkShiftService from "@/components/shiftscheduler/WorkShiftService";
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";

export default {
  name: "ShiftView",
  components: {
    WorkShiftWizard,
  },
  data() {
    return {
      isCreation: true,
      workShiftId: null,
      initialShift: null,
      productionUnits: [],
      showWizard: false,
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory"]),
    factoryId() {
      return this.activeFactory ? this.activeFactory.id : null;
    },
    isFactoryIdDefined() {
      return this.activeFactory != null;
    },
    title() {
      return this.isCreation ? this.$t("shiftWizard.createShift") : this.$t("shiftWizard.editShift");
    },
  },
  watch: {
    activeFactory() {
      this.fetchProductionUnits(null);
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    async fetchWorkShift(workShiftId) {
      WorkShiftService.getWorkShift(workShiftId)
        .then((response) => this.handleWorkShiftRetrievalResponse(response, workShiftId))
        .catch((error) => this.handleRetrievalError(error.response));
    },
    handleWorkShiftRetrievalResponse(httpResponse, workShiftId) {
      if (httpResponse.status === 200) {
        let ws = httpResponse.data;
        this.fetchProductionUnits(ws);
      } else {
        this.showOperationError(this.$t("shiftWizard.errors.noWorkShiftFound", { workShiftId: workShiftId }));
        this.$router.push(RouteService.toShiftSchedules());
      }
    },
    handleRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.$router.push(RouteService.toShiftSchedules());
    },
    async fetchProductionUnits(workShift) {
      if (this.factoryId) {
        ProductionUnitService.getProductionUnits(this.factoryId)
          .then((response) => this.handleProductionUnitsRetrievalResponse(response, workShift))
          .catch((error) => this.handleProductionUnitsRetrievalError(error.response));
      }
    },
    handleProductionUnitsRetrievalResponse(httpResponse, workShift) {
      if (httpResponse.status === 200) {
        this.productionUnits = httpResponse.data;
        this.initialShift = workShift;
        this.showWizard = true;
      }
    },
    handleProductionUnitsRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
  },
  async mounted() {
    this.workShiftId = this.$route.params.id;
    if (this.workShiftId) {
      this.isCreation = false;
      await this.fetchWorkShift(this.workShiftId);
    } else {
      this.isCreation = true;
      if (this.isFactoryIdDefined) {
        await this.fetchProductionUnits(null);
      }
    }
  },
};
</script>
