<template>
  <wx-text-field
    v-model="time"
    :wxid="$options.name"
    :disabled="isDisabled"
    :label="title"
    :rules="rules"
    :error-messages="validationErrors"
    :class="{ 'prepend-icon': showPrependIcon }"
    :prepend-icon="prependIcon"
    :hide-details="hideDetails"
    class="wx-time-picker"
    type="time"
  />
</template>

<script>
import WxTextField from "@/components/ui/WxTextField";

export default {
  name: "WxTimePicker",
  components: {
    WxTextField,
  },
  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    value: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    showPrependIcon: {
      type: Boolean,
      default: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      time: this.value,
      validationErrors: this.errorMessages,
    };
  },
  watch: {
    value() {
      this.time = this.value;
    },
    errorMessages() {
      this.validationErrors = this.errorMessages;
    },
    time() {
      this.emitTime();
    },
  },
  computed: {
    prependIcon() {
      return this.showPrependIcon ? "$calendarItemIcon" : "";
    },
  },
  methods: {
    emitTime() {
      this.$emit("input", this.time);
      this.$emit("updated");
    },
  },
};
</script>

<style lang="scss" scoped>
.wx-time-picker {
  ::v-deep input[type="time"] {
    margin-top: 13px; // contrain height to match default WxTextField

    // html5 native clock icon
    &::-webkit-calendar-picker-indicator {
      filter: invert(60%); // = opacity: 0.6
    }
  }
  // legacy : Hide html5 icon if we show a v-icon
  &.prepend-icon {
    ::v-deep input[type="time"]::-webkit-calendar-picker-indicator {
      visibility: hidden;
    }
  }
}
</style>
