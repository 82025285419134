<template>
  <fragment>
    <section class="wx-checkbox-weekdays" :class="{ 'first-characters': showDaysFirstCharacter }">
      <label v-if="checkboxesMainLabel" class="checkboxes-label">{{ checkboxesMainLabel }}</label>
      <div class="checkboxes-wrapper">
        <v-checkbox
          v-model="weekdays.monday"
          :label="getDayLabel($t('common.dayNames.monday'))"
          :title="$t('common.dayNames.monday')"
          :rules="rules"
          @click="save()"
        />
        <v-checkbox
          v-model="weekdays.tuesday"
          :label="getDayLabel($t('common.dayNames.tuesday'))"
          :title="$t('common.dayNames.tuesday')"
          :rules="rules"
          @click="save()"
        />
        <v-checkbox
          v-model="weekdays.wednesday"
          :label="getDayLabel($t('common.dayNames.wednesday'))"
          :title="$t('common.dayNames.wednesday')"
          :rules="rules"
          @click="save()"
        />
        <v-checkbox
          v-model="weekdays.thursday"
          :label="getDayLabel($t('common.dayNames.thursday'))"
          :title="$t('common.dayNames.thursday')"
          :rules="rules"
          @click="save()"
        />
        <v-checkbox
          v-model="weekdays.friday"
          :label="getDayLabel($t('common.dayNames.friday'))"
          :title="$t('common.dayNames.friday')"
          :rules="rules"
          @click="save()"
        />
        <v-checkbox
          v-model="weekdays.saturday"
          :label="getDayLabel($t('common.dayNames.saturday'))"
          :title="$t('common.dayNames.saturday')"
          :rules="rules"
          @click="save()"
        />
        <v-checkbox
          v-model="weekdays.sunday"
          :label="getDayLabel($t('common.dayNames.sunday'))"
          :title="$t('common.dayNames.sunday')"
          :rules="rules"
          @click="save()"
        />
      </div>
    </section>
  </fragment>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WxCheckboxWeekdays",
  props: {
    value: {
      type: Object,
      required: true,
    },
    showDaysFirstCharacter: {
      type: Boolean,
      default: false,
    },
    checkboxesMainLabel: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      weekdays: this.value
        ? this.value
        : {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
          },
    };
  },
  watch: {
    value() {
      this.weekdays = this.value;
    },
  },
  computed: {
    ...mapGetters("user", ["isSimplifiedChinese"]),
  },
  methods: {
    getDayLabel(day) {
      if (!this.$vuetify.breakpoint.xs) {
        if (this.isSimplifiedChinese) {
          return day.charAt(1); // In Simplified Chinese, show the second character of the week day
        } else {
          return day.charAt(0);
        }
      } else {
        return day;
      }
    },
    save() {
      this.$emit("input", this.weekdays);
      this.$emit("updated");
    },
  },
};
</script>

<style lang="scss" scoped>
.wx-checkbox-weekdays {
  position: relative;

  .checkboxes-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .v-input--selection-controls {
      &.v-input--checkbox {
        max-width: none;
        margin-top: 0.5rem;
        margin-right: 1rem;

        ::v-deep .v-label {
          text-transform: capitalize;
        }

        // Responsive
        @media ($wx-sm-min) {
          margin-right: 0;
        }
      }
    }
  }

  // prop: show-days-first-character = Enabled
  &.first-characters {
    .checkboxes-label {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      padding-bottom: 0.4rem;
      white-space: nowrap;
    }

    @media ($wx-lg-min) {
      .checkboxes-label {
        margin-right: 2rem;
      }
    }

    @media ($wx-sm-min) {
      display: flex;
      flex-direction: row;

      .checkboxes-wrapper {
        justify-content: space-between;

        ::v-deep .v-input--selection-controls {
          margin-top: 0;
        }
        .v-input--selection-controls {
          &.v-input--checkbox {
            ::v-deep .v-input__control {
              position: relative;
              margin-top: 0;
              margin-right: 0;

              .v-messages {
                display: none;
              }
              .v-input--selection-controls__input {
                position: absolute;
                opacity: 0;
              }
            }

            // round labels
            ::v-deep .v-label {
              justify-content: center;
              min-width: 1.4rem;
              margin-inline: 0; // over ruled by `justify-content: space-between`
              padding: 0.2rem 0.6rem;
              font-size: var(--font-size-sm);
              background-color: var(--color-secondary);
              border: 1px solid var(--color-secondary);
              border-radius: 50%;

              // Use JS to wrapp other then first letters :  M<span.other-letters>onday</span>
              .other-letters {
                display: none;
              }

              &.error--text {
                border-color: var(--color-error);
              }
              &.warning--text {
                border-color: var(--color-warning);
              }
              &.success--text {
                border-color: var(--color-success);
              }
            }

            // checked round labels
            &.v-input--is-label-active {
              ::v-deep .v-label {
                background-color: var(--color-primary);
              }
            }

            // larger then a vertical phone
            @media (min-width: $wx-min-width-phone-vertical) {
              ::v-deep .v-label {
                min-width: 2.5rem;
                margin-inline: 0.2rem;
                padding: 0.6rem;
                font-size: var(--font-size-norm);
              }
            }
          }
        }
      }
    }
  }
}
</style>
